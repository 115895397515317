import { useEffect, useRef } from "react";
import { useAppDispatch, useAppSelector } from "./storeHooks";
import { AppState } from "../app/store";
import {
  listenLatestCaptureForAdmin,
  setLeftImage,
  setRightImage,
} from "../features/admin/adminSlice";
import {
  addUnsubscribeFunction,
  removeUnsubscribeFunction,
} from "../utils/unsubscribeManager";

type Unsubscribe = () => void;

export const useAdminLastCaptureSubscription = (userId: string | undefined) => {
  const dispatch = useAppDispatch();
  const unsubscribeRef = useRef<Unsubscribe | null>(null);

  useEffect(() => {
    // If there is an existing subscription, unsubscribe before creating a new one
    if (unsubscribeRef.current) {
      unsubscribeRef.current();
      unsubscribeRef.current = null;
      if (userId) {
        removeUnsubscribeFunction(userId);
      }
    }

    if (!userId) {
      return;
    }

    const subscribe = async () => {
      try {
        console.log(`Subscribing to captures for user: ${userId}`);
        const unsubscribe = (await dispatch(
          listenLatestCaptureForAdmin(userId)
        ).unwrap()) as Unsubscribe;
        unsubscribeRef.current = unsubscribe;
        addUnsubscribeFunction(userId, unsubscribe);
      } catch (error) {
        console.error("Error subscribing to latest captures: ", error);
      }
    };

    subscribe();

    return () => {
      if (unsubscribeRef.current) {
        console.log(`Unsubscribing from captures for user: ${userId}`);
        unsubscribeRef.current();
        unsubscribeRef.current = null;
        removeUnsubscribeFunction(userId);
      }
    };
  }, [userId, dispatch]);
};
