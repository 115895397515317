export const isSignedUrlValid = (url: string): boolean => {
  try {
    const urlObj = new URL(url);
    const expiresParam = urlObj.searchParams.get("Expires");

    if (!expiresParam) {
      return false;
    }
    const expiresTimestamp = parseInt(expiresParam, 10);
    if (isNaN(expiresTimestamp)) {
      return false;
    }
    const currentTimestamp = Math.floor(Date.now() / 1000);
    return currentTimestamp < expiresTimestamp;
  } catch (error) {
    console.error("Invalid URL:", error);
    return false;
  }
};
