import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks/storeHooks";
import { AppState } from "../../app/store";
import {
  Row,
  Col,
  Card,
  Collapse,
  Descriptions,
  Grid,
  Input,
  Button,
  Space,
  message,
} from "antd";
import { useLastCaptureSubscription } from "../../hooks/useLastCaptureSubscription";
import { updateCaptureWithFeedback } from "../../features/captures/capturesSlice";
import CaptureViewer from "../CaptureViewer/CaptureViewer";
import {
  setLeftImage,
  setRightImage,
} from "../../features/captures/capturesSlice";
import { getLaunchAngleText } from "../../utils/launchAngleHelper";

const { useBreakpoint } = Grid;

const Dashboard: React.FC = () => {
  const [reported, setReported] = useState<boolean>(false);
  const [feedback, setFeedback] = useState<string>("");
  const [speedUnit, setSpeedUnit] = useState<"mph" | "km/h" | "m/s">("mph");

  useLastCaptureSubscription();
  const dispatch = useAppDispatch();
  const lastCapture = useAppSelector(
    (state: AppState) => state.captures.lastCapture
  );
  const currentUser = useAppSelector(
    (state: AppState) => state.user.currentUser
  );

  const styles = {
    maxWidth: {
      maxWidth: "100%",
    },
  };

  const handleReport = () => {
    if (lastCapture) {
      dispatch(
        updateCaptureWithFeedback({
          userFeedback: feedback,
          userDocId: currentUser?.uid || "",
          lastCapture: lastCapture,
        })
      );
      setFeedback("");
      message.success("Your feedback has been submitted.");
    }
  };

  const handleEarlyReport = () => {
    if (lastCapture) {
      dispatch(
        updateCaptureWithFeedback({
          userFeedback: feedback,
          userDocId: currentUser?.uid || "",
          lastCapture: lastCapture,
          additionalData: {
            reported_too_early: true,
          },
        })
      );
      setFeedback("");
      message.success("Photo too early feedback has been submitted.");
    }
  };

  const handleLateReport = () => {
    if (lastCapture) {
      dispatch(
        updateCaptureWithFeedback({
          userFeedback: feedback,
          userDocId: currentUser?.uid || "",
          lastCapture: lastCapture,
          additionalData: {
            reported_too_late: true,
          },
        })
      );
      setFeedback("");
      message.success("Photo too late feedback has been submitted.");
    }
  };
  const handleSpeedUnitChange = () => {
    if (speedUnit === "mph") {
      setSpeedUnit("km/h");
    } else if (speedUnit === "km/h") {
      setSpeedUnit("m/s");
    } else {
      setSpeedUnit("mph");
    }
  };

  useEffect(() => {
    const fetchImages = async () => {
      if (lastCapture?.left_vizualization) {
        dispatch(setLeftImage(lastCapture.left_vizualization));
      }
      if (lastCapture?.right_visualization) {
        dispatch(setRightImage(lastCapture.right_visualization));
      }
    };

    fetchImages();

    if (lastCapture?.userFeedback) {
      setFeedback(lastCapture.userFeedback);
    } else {
      setFeedback("");
    }
    if (lastCapture?.reported) {
      setReported(lastCapture.reported);
    } else {
      setReported(false);
    }
  }, [lastCapture, dispatch]);

  const screens = useBreakpoint();

  const collapseItems = [
    {
      key: "1",
      label: reported ? "Shot Data -> Reported" : "Shot Data",
      children: (
        <Descriptions bordered column={1} style={{ width: "100%" }}>
          <Descriptions.Item
            label={<span onClick={handleSpeedUnitChange}>Ball Speed:</span>}
          >
            {lastCapture?.ball_speed
              ? speedUnit === "mph"
                ? (lastCapture.ball_speed * 2.237).toFixed(1)
                : speedUnit === "km/h"
                ? (lastCapture.ball_speed * 3.6).toFixed(1)
                : lastCapture.ball_speed.toFixed(1)
              : "N/A"}{" "}
            ({speedUnit.toLowerCase()})
          </Descriptions.Item>
          <Descriptions.Item label="Vertical Launch Angle">
            {getLaunchAngleText(lastCapture?.vertical_launch_angle, true)}
          </Descriptions.Item>
          <Descriptions.Item label="Horizontal Launch Angle">
            {getLaunchAngleText(lastCapture?.horizontal_launch_angle)}
          </Descriptions.Item>
          {/* <Descriptions.Item label="Sidespin">
            {lastCapture?.sidespin?.toFixed(1) ?? "N/A"}
          </Descriptions.Item>
          <Descriptions.Item label="Backspin">
            {lastCapture?.backspin?.toFixed(1) ?? "N/A"}
          </Descriptions.Item> */}
          <Descriptions.Item label="Total Spin">
            {lastCapture?.spin?.toFixed(0) ?? "N/A"} (rpm)
          </Descriptions.Item>
          <Descriptions.Item label="Spin Axis">
            {getLaunchAngleText(lastCapture?.spin_axis)}
          </Descriptions.Item>
        </Descriptions>
      ),
      style: reported ? { backgroundColor: "red" } : {},
    },
  ];

  const renderLayout = () => {
    if (!lastCapture) {
      return <div>Waiting for your next shot...</div>;
    }
    if (screens.xs) {
      // Mobile layout
      return (
        <Row justify="center" style={{ ...styles.maxWidth, padding: "5px" }}>
          <Col xs={24}>
            <Space
              direction="vertical"
              size={8}
              style={{ ...styles.maxWidth, minWidth: "100%" }}
            >
              <Card
                title={reported ? "Shot Data -> Reported" : "Shot Data"}
                bodyStyle={{ padding: "0" }}
                style={reported ? { backgroundColor: "red" } : {}}
              >
                <ul style={{ listStyleType: "none", padding: 0 }}>
                  <li
                    style={{
                      display: "grid",
                      gridTemplateColumns: "1fr auto 1fr",
                      justifyContent: "center",
                    }}
                  >
                    <strong
                      style={{ textAlign: "right" }}
                      onClick={handleSpeedUnitChange}
                    >
                      Ball Speed:
                    </strong>
                    {speedUnit === "mph" && lastCapture.ball_speed
                      ? (lastCapture.ball_speed * 2.237).toFixed(1)
                      : speedUnit === "km/h" && lastCapture.ball_speed
                      ? (lastCapture.ball_speed * 3.6).toFixed(1)
                      : lastCapture.ball_speed?.toFixed(1)}{" "}
                    ({speedUnit.toLowerCase()})
                  </li>
                  {/* <li
                    style={{
                      display: "grid",
                      gridTemplateColumns: "1fr auto 1fr",
                      justifyContent: "center",
                    }}
                  >
                    <strong style={{ textAlign: "right" }}>Sidespin:</strong>{" "}
                    {lastCapture.sidespin?.toFixed(1)}
                  </li>
                  <li
                    style={{
                      display: "grid",
                      gridTemplateColumns: "1fr auto 1fr",
                      justifyContent: "center",
                    }}
                  >
                    <strong style={{ textAlign: "right" }}>Backspin:</strong>{" "}
                    {lastCapture.backspin?.toFixed(1)}
                  </li> */}
                  <li
                    style={{
                      display: "grid",
                      gridTemplateColumns: "1fr auto 1fr",
                      justifyContent: "center",
                    }}
                  >
                    <strong style={{ textAlign: "right" }}>
                      Vertical Launch Angle:
                    </strong>{" "}
                    {getLaunchAngleText(
                      lastCapture.vertical_launch_angle,
                      true
                    )}
                  </li>
                  <li
                    style={{
                      display: "grid",
                      gridTemplateColumns: "1fr auto 1fr",
                      justifyContent: "center",
                    }}
                  >
                    <strong style={{ textAlign: "right" }}>
                      Horizontal Launch Angle:
                    </strong>{" "}
                    {getLaunchAngleText(lastCapture.horizontal_launch_angle)}
                  </li>
                  <li
                    style={{
                      display: "grid",
                      gridTemplateColumns: "1fr auto 1fr",
                      justifyContent: "center",
                    }}
                  >
                    <strong style={{ textAlign: "right" }}>Total Spin:</strong>{" "}
                    {lastCapture.spin?.toFixed(0)} RPM
                  </li>
                  <li
                    style={{
                      display: "grid",
                      gridTemplateColumns: "1fr auto 1fr",
                      justifyContent: "center",
                    }}
                  >
                    <strong style={{ textAlign: "right" }}>Spin Axis:</strong>{" "}
                    {getLaunchAngleText(lastCapture?.spin_axis)}
                  </li>
                </ul>
              </Card>
              <CaptureViewer lastCapture={lastCapture} />
              <Input.TextArea
                value={feedback}
                onChange={(e) => setFeedback(e.target.value)}
                placeholder="Enter your feedback here"
                style={{ height: "200px", width: "100%" }}
              />
              <Row>
                <Col span={12}>
                  <Button type="default" onClick={handleEarlyReport}>
                    Photo Too Early
                  </Button>
                </Col>
                <Col span={12}>
                  <Button type="default" onClick={handleLateReport}>
                    Photo Too Late
                  </Button>
                </Col>
              </Row>
              <Button type="primary" danger onClick={handleReport}>
                Report
              </Button>
            </Space>
          </Col>
        </Row>
      );
    } else {
      // Desktop layout
      return (
        <Row>
          <Col style={{ paddingRight: "15px" }} span={17}>
            <CaptureViewer lastCapture={lastCapture} />
          </Col>
          <Col span={7}>
            <Space
              direction="vertical"
              size={16}
              style={{ width: "100%", minWidth: "100%" }}
            >
              <Collapse
                defaultActiveKey={["1"]}
                style={{ width: "100%", minWidth: "100%" }}
                items={collapseItems}
              />
              <Input.TextArea
                value={feedback}
                onChange={(e) => setFeedback(e.target.value)}
                placeholder="Enter your feedback here"
                style={{ height: "200px", width: "100%" }} // Increase the default height
              />
              <Row>
                <Col span={12}>
                  <Button type="default" onClick={handleEarlyReport}>
                    Photo Too Early
                  </Button>
                </Col>
                <Col span={12}>
                  <Button type="default" onClick={handleLateReport}>
                    Photo Too Late
                  </Button>
                </Col>
              </Row>
              <Button type="primary" danger onClick={handleReport}>
                Report
              </Button>
            </Space>
          </Col>
        </Row>
      );
    }
  };

  return renderLayout();
};

export default Dashboard;
