import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useAppSelector } from "../hooks/storeHooks";
import MainLayout from "../components/Layouts/MainLayout/MainLayout";

const PrivateRoute: React.FC = () => {
  const currentUser = useAppSelector((state) => state.user.currentUser);

  // If there's no user, redirect to login
  return currentUser ? (
    <MainLayout>
      <Outlet />
    </MainLayout>
  ) : (
    <Navigate to="/login" />
  );
};

export default PrivateRoute;
