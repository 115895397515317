import React, { useEffect, useState } from "react";
import { Row, Col, Card, Image, Space, Grid } from "antd";
import { getFirebaseImageURL } from "../../utils/firebase-storage";
import { Capture } from "../../features/captures/capturesSlice";
import { isSignedUrlValid } from "../../utils/urlUtil";

const CaptureViewer = ({
  lastCapture,
}: {
  lastCapture: Capture | undefined;
}) => {
  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();

  const [leftImage, setLeftImage] = useState<string | null>(null);
  const [rightImage, setRightImage] = useState<string | null>(null);

  useEffect(() => {
    const getImageUrl = async (
      signedUrl?: string,
      unsignedPath?: string
    ): Promise<string | null> => {
      if (signedUrl && isSignedUrlValid(signedUrl)) {
        return signedUrl;
      } else if (unsignedPath) {
        return await getFirebaseImageURL(unsignedPath);
      } else {
        return null;
      }
    };

    const fetchImages = async () => {
      if (lastCapture) {
        const leftImg = await getImageUrl(
          lastCapture.left_vizualization_signed_url,
          lastCapture.left_vizualization
        );
        setLeftImage(leftImg);
        const rightImg = await getImageUrl(
          lastCapture.right_visualization_signed_url,
          lastCapture.right_visualization
        );
        setRightImage(rightImg);
      } else {
        setLeftImage(null);
        setRightImage(null);
      }
    };

    fetchImages();
  }, [lastCapture]);

  const styles = {
    maxWidth: {
      maxWidth: "100%",
    },
  };

  const renderLayout = () => {
    if (!lastCapture) {
      return <div>Waiting for your next shot...</div>;
    }
    if (screens.xs) {
      // Mobile layout
      return (
        <Row justify="center" style={{ ...styles.maxWidth, padding: "5px" }}>
          <Col xs={24}>
            <Space
              direction="vertical"
              size={8}
              style={{ ...styles.maxWidth, minWidth: "100%" }}
            >
              <Card title="Left" style={{ ...styles.maxWidth, margin: "5px" }}>
                {leftImage && (
                  <Image src={leftImage} width="100%" preview={false} />
                )}
              </Card>
              <Card title="Right" style={{ ...styles.maxWidth, margin: "5px" }}>
                {rightImage && (
                  <Image src={rightImage} width="100%" preview={false} />
                )}
              </Card>
            </Space>
          </Col>
        </Row>
      );
    } else {
      // Desktop layout
      return (
        <Row>
          <Col span={24}>
            <Row gutter={16} justify="center">
              <Col span={12}>
                <Card title="Left">
                  {leftImage && (
                    <Image src={leftImage} width="100%" preview={false} />
                  )}
                </Card>
              </Col>
              <Col span={12}>
                <Card title="Right">
                  {rightImage && (
                    <Image src={rightImage} width="100%" preview={false} />
                  )}
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
      );
    }
  };

  return renderLayout();
};

export default CaptureViewer;
