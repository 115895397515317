// src/pages/LoginPage.jsx

import React, { useEffect } from "react";
import { Form, Input, Button, Card, Typography, Divider, message } from "antd";
import {
  login,
  signInWithGoogle,
  signInWithApple, // Import the new Apple sign-in action
  clearError,
} from "../../features/user/userSlice";
import backgroundImage from "../../assets/images/login-bg.jpg"; // Import your image
import googleLogo from "../../assets/images/g-logo.png"; // Import the Google logo
import appleLogo from "../../assets/images/apple-logo.png"; // Import the Apple logo
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../hooks/storeHooks";

const { Title } = Typography;

const LoginPage = () => {
  const dispatch = useAppDispatch();
  const loginError = useAppSelector((state) => state.user.error);

  useEffect(() => {
    if (loginError) {
      message.error(loginError);
      dispatch(clearError());
    }
  }, [loginError, dispatch]);

  const onFinish = (values: { username: string; password: string }) => {
    dispatch(login(values.username, values.password));
  };

  const handleGoogleSignIn = () => {
    dispatch(signInWithGoogle());
  };

  const handleAppleSignIn = () => {
    dispatch(signInWithApple());
  };

  return (
    <div
      style={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "relative",
        backgroundColor: "#f0f2f5",
      }}
    >
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          height: "100%",
          width: "100%",
          background:
            "radial-gradient(circle at center, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0.7) 100%)",
        }}
      />
      <Card
        style={{
          width: 375,
          position: "relative",
          boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
          paddingTop: "5px",
        }}
      >
        <Title level={2} style={{ textAlign: "center", paddingBottom: "20px" }}>
          Openlaunch Login
        </Title>
        <Form
          name="login"
          initialValues={{ remember: true }}
          onFinish={onFinish}
        >
          <Form.Item
            label="Username"
            name="username"
            rules={[{ required: true, message: "Please input your username!" }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Password"
            name="password"
            rules={[{ required: true, message: "Please input your password!" }]}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit" style={{ width: "100%" }}>
              Log in
            </Button>
          </Form.Item>
        </Form>
        <Divider>Or</Divider>
        <Button
          type="default"
          onClick={handleGoogleSignIn}
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginBottom: "16px",
          }}
        >
          <img
            src={googleLogo}
            alt="Google Logo"
            style={{ marginRight: "10px", width: "20px", height: "20px" }}
          />
          Log in with Google
        </Button>
        <Button
          type="default"
          onClick={handleAppleSignIn}
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img
            src={appleLogo}
            alt="Apple Logo"
            style={{ marginRight: "10px", width: "20px", height: "20px" }}
          />
          Log in with Apple
        </Button>
      </Card>
    </div>
  );
};

export default LoginPage;
