export const getLaunchAngleText = (
  launchAngle: number | undefined,
  vertical?: boolean
): string => {
  if (launchAngle === undefined) {
    return "N/A";
  }
  if (vertical) {
    return launchAngle.toFixed(1) + "°";
  }
  if (launchAngle > 0) {
    return launchAngle.toFixed(1).toString() + "°R";
  } else if (launchAngle < 0) {
    return Math.abs(launchAngle).toFixed(1).toString() + "°L";
  } else {
    return "0°";
  }
};
