// src/features/admin/adminSlice.ts
import {
  createSlice,
  PayloadAction,
  createAsyncThunk,
  createAction,
} from "@reduxjs/toolkit";
import {
  collection,
  doc,
  query,
  where,
  orderBy,
  getDocs,
  onSnapshot,
  getCountFromServer,
  getDoc,
  limit,
} from "firebase/firestore";
import { db, functions } from "../../app/firebase";
import { Capture } from "../captures/capturesSlice";
import { httpsCallable } from "firebase/functions";

export interface User {
  email: string;
  uid: string;
  reportedCaptures: Capture[];
  leftImage: string | null;
  rightImage: string | null;
  totalCapturesCount: number;
  reportedCapturesCount: number;
  lastCapture?: Capture;
}

interface AdminState {
  users: User[];
  subscribedUser: User | null;
  unsubscribeFromCaptures: (() => void) | null;
}

const initialState: AdminState = {
  users: [],
  subscribedUser: null,
  unsubscribeFromCaptures: null,
};

const getUserDocId = async (userUid: string): Promise<string> => {
  const userDocRef = doc(db, "users", userUid);
  const userDocSnap = await getDoc(userDocRef);

  if (userDocSnap.exists()) {
    return userUid;
  } else {
    const usersCollection = collection(db, "users");
    const q = query(usersCollection, where("uid", "==", userUid), limit(1));
    const querySnapshot = await getDocs(q);

    if (!querySnapshot.empty) {
      const userDoc = querySnapshot.docs[0];
      return userDoc.id;
    } else {
      throw new Error("User document not found in Firestore");
    }
  }
};

export const fetchUsers = createAsyncThunk(
  "admin/fetchUsers",
  async (_, { rejectWithValue }) => {
    try {
      const listUsers = httpsCallable(functions, "listUsers");
      const response = await listUsers();
      const usersData = (response.data as any).users as User[];
      const users = usersData.map((user) => ({
        ...user,
        reportedCaptures: [],
        leftImage: null,
        rightImage: null,
        totalCapturesCount: 0,
        reportedCapturesCount: 0,
        lastCapture: undefined,
      }));
      return users;
    } catch (error) {
      console.error("Error fetching users:", error);
      return rejectWithValue("Failed to fetch users");
    }
  }
);

const fetchUserCapturesCount = async (docId: string) => {
  const coll = collection(db, "users", docId, "captures");
  const q = query(coll, where("valid_launch", "!=", false));
  const snapshot = await getCountFromServer(q);
  return snapshot.data().count;
};

// Updated function to fetch the reported captures count using docId
const fetchReportedUserCapturesCount = async (docId: string) => {
  const coll = collection(db, "users", docId, "captures");
  const q = query(coll, where("reported", "==", true));
  const snapshot = await getCountFromServer(q);
  return snapshot.data().count;
};

// Updated selectUserAndFetchDetails function using getUserDocId
export const selectUserAndFetchDetails = createAsyncThunk(
  "admin/selectUserAndFetchDetails",
  async (user: User) => {
    const docId = await getUserDocId(user.uid);
    const totalCapturesCount = await fetchUserCapturesCount(docId);
    const reportedCapturesCount = await fetchReportedUserCapturesCount(docId);
    return { ...user, totalCapturesCount, reportedCapturesCount };
  }
);

export const listenLatestCaptureForAdmin = createAsyncThunk(
  "admin/listenLatestCaptureForAdmin",
  async (userUid: string, { dispatch }) => {
    const docId = await getUserDocId(userUid);

    const now = new Date();
    const capturesCollectionRef = collection(db, "users", docId, "captures");
    const qCaptures = query(
      capturesCollectionRef,
      where("recorded", ">", now),
      orderBy("recorded", "desc")
    );

    const unsubscribe = onSnapshot(qCaptures, (snapshot) => {
      snapshot.docChanges().forEach(async (change) => {
        if (change.type === "added" || change.type === "modified") {
          const capture = {
            ...change.doc.data(),
            captureId: change.doc.id,
            recorded: change.doc.data().recorded.toDate(),
          } as Capture;

          dispatch(lastCaptureAdded(capture));
        }
      });
    });

    return unsubscribe;
  }
);

export const lastCaptureAdded = createAction<Capture>("admin/lastCaptureAdded");

const adminSlice = createSlice({
  name: "admin",
  initialState,
  reducers: {
    setSubscribedUser: (state, action: PayloadAction<User | null>) => {
      state.subscribedUser = action.payload;
    },
    setUnsubscribeFromCaptures: (
      state,
      action: PayloadAction<(() => void) | null>
    ) => {
      state.unsubscribeFromCaptures = action.payload;
    },
    setLeftImage: (state, action: PayloadAction<string>) => {
      if (state.subscribedUser) {
        state.subscribedUser.leftImage = action.payload;
      }
    },
    setRightImage: (state, action: PayloadAction<string>) => {
      if (state.subscribedUser) {
        state.subscribedUser.rightImage = action.payload;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchUsers.fulfilled, (state, action) => {
      state.users = action.payload;
    });
    builder.addCase(lastCaptureAdded, (state, action) => {
      if (state.subscribedUser) {
        state.subscribedUser.lastCapture = action.payload;
      }
    });
  },
});

export const {
  setSubscribedUser,
  setUnsubscribeFromCaptures,
  setLeftImage,
  setRightImage,
} = adminSlice.actions;

export default adminSlice.reducer;
