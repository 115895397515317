import React, { useEffect, useState, useCallback } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks/storeHooks";
import { useAdminLastCaptureSubscription } from "../../hooks/useAdminLastCaptureSubscription";
import CaptureViewer from "../CaptureViewer/CaptureViewer";
import {
  fetchUsers,
  setSubscribedUser,
  setUnsubscribeFromCaptures,
  listenLatestCaptureForAdmin,
  selectUserAndFetchDetails,
  User,
} from "../../features/admin/adminSlice";
import {
  Button,
  Space,
  Card,
  AutoComplete,
  Row,
  Col,
  Descriptions,
  Tabs,
  Table,
  Grid,
} from "antd";
import { getLaunchAngleText } from "../../utils/launchAngleHelper";
const { TabPane } = Tabs;
const { useBreakpoint } = Grid;

const Admin: React.FC = () => {
  const dispatch = useAppDispatch();
  const [speedUnit, setSpeedUnit] = useState<"mph" | "km/h" | "m/s">("mph");
  const [loading, setLoading] = useState(false);

  const { users, subscribedUser, unsubscribeFromCaptures } = useAppSelector(
    (state) => state.admin
  );
  const subscribedUserLastCapture = subscribedUser?.lastCapture;

  const [searchValue, setSearchValue] = useState<string>("");
  const [selectedUser, setSelectedUser] = useState<User | null>(null);

  useAdminLastCaptureSubscription(subscribedUser?.uid);

  useEffect(() => {
    dispatch(fetchUsers());
  }, [dispatch]);

  // Handle input change in the AutoComplete component
  const handleChange = useCallback((value: string) => {
    setSearchValue(value);
    setSelectedUser(null); // Reset selected user if input changes
  }, []);

  // Handle selecting an option from the dropdown
  const handleSelect = useCallback(
    (value: string) => {
      setSearchValue(value);
      const user = users.find((user) => user.email === value);
      if (user) {
        setSelectedUser(user);
      } else {
        setSelectedUser(null);
      }
    },
    [users]
  );

  const handleSubscribe = useCallback(
    async (user: User) => {
      if (loading) return;
      setLoading(true);
      try {
        if (unsubscribeFromCaptures) {
          unsubscribeFromCaptures();
        }

        // Fetch user details including shot counts
        const userWithDetails = await dispatch(
          selectUserAndFetchDetails(user)
        ).unwrap();

        const unsubscribe = await dispatch(
          listenLatestCaptureForAdmin(user.uid)
        ).unwrap();
        dispatch(setSubscribedUser(userWithDetails));
        dispatch(setUnsubscribeFromCaptures(unsubscribe));
      } catch (error) {
        console.error("Error subscribing:", error);
      } finally {
        setLoading(false);
      }
    },
    [unsubscribeFromCaptures, dispatch, loading]
  );

  const handleUnsubscribe = useCallback(() => {
    if (loading) return;
    setLoading(true);
    try {
      if (unsubscribeFromCaptures) {
        unsubscribeFromCaptures();
      }
      dispatch(setUnsubscribeFromCaptures(null));
      dispatch(setSubscribedUser(null));
      setSelectedUser(null);
      setSearchValue("");
    } catch (error) {
      console.error("Error unsubscribing:", error);
    } finally {
      setLoading(false);
    }
  }, [unsubscribeFromCaptures, dispatch, loading]);

  const handleSpeedUnitChange = () => {
    setSpeedUnit((prevUnit) =>
      prevUnit === "mph" ? "km/h" : prevUnit === "km/h" ? "m/s" : "mph"
    );
  };

  const screens = useBreakpoint();

  const renderMobileLayout = () => (
    <Row justify="center" style={{ padding: "5px" }}>
      <Col xs={24}>
        <Space
          direction="vertical"
          size={8}
          style={{ width: "100%", minWidth: "100%" }}
        >
          <Space>
            <AutoComplete
              value={searchValue}
              style={{ width: 300 }}
              options={users
                .filter((user) => user.email)
                .map((user) => ({ value: user.email }))}
              placeholder="Search for a user"
              filterOption={(inputValue, option) =>
                option!.value.toUpperCase().includes(inputValue.toUpperCase())
              }
              onSelect={handleSelect}
              onChange={handleChange}
            />
            <Button
              type={subscribedUser ? "default" : "primary"}
              danger={!!subscribedUser}
              onClick={
                subscribedUser
                  ? handleUnsubscribe
                  : () => handleSubscribe(selectedUser!)
              }
              disabled={loading || (!subscribedUser && !selectedUser)} // Disable when loading or no selected user
            >
              {subscribedUser ? "Unsubscribe" : "Subscribe"}
            </Button>
          </Space>
          {subscribedUserLastCapture ? (
            <>
              <Card
                title={
                  subscribedUserLastCapture?.reported
                    ? "Shot Data -> Reported"
                    : "Shot Data"
                }
                bodyStyle={{ padding: "0" }}
                style={
                  subscribedUserLastCapture?.reported
                    ? { backgroundColor: "red" }
                    : {}
                }
              >
                <ul style={{ listStyleType: "none", padding: 0 }}>
                  <li
                    style={{
                      display: "grid",
                      gridTemplateColumns: "1fr auto 1fr",
                      justifyContent: "center",
                    }}
                  >
                    <strong
                      style={{ textAlign: "right" }}
                      onClick={handleSpeedUnitChange}
                    >
                      Ball Speed:
                    </strong>
                    {speedUnit === "mph" && subscribedUserLastCapture.ball_speed
                      ? (subscribedUserLastCapture.ball_speed * 2.237).toFixed(
                          1
                        )
                      : speedUnit === "km/h" &&
                        subscribedUserLastCapture.ball_speed
                      ? (subscribedUserLastCapture.ball_speed * 3.6).toFixed(1)
                      : subscribedUserLastCapture.ball_speed?.toFixed(1)}{" "}
                    {speedUnit.toLowerCase()}
                  </li>
                  {/* <li
                    style={{
                      display: "grid",
                      gridTemplateColumns: "1fr auto 1fr",
                      justifyContent: "center",
                    }}
                  >
                    <strong style={{ textAlign: "right" }}>Backspin:</strong>
                    {subscribedUserLastCapture.backspin?.toFixed(1)}
                  </li>
                  <li
                    style={{
                      display: "grid",
                      gridTemplateColumns: "1fr auto 1fr",
                      justifyContent: "center",
                    }}
                  >
                    <strong style={{ textAlign: "right" }}>Sidespin:</strong>
                    {subscribedUserLastCapture.sidespin?.toFixed(1)}
                  </li> */}
                  <li
                    style={{
                      display: "grid",
                      gridTemplateColumns: "1fr auto 1fr",
                      justifyContent: "center",
                    }}
                  >
                    <strong style={{ textAlign: "right" }}>
                      Vertical Launch Angle:
                    </strong>
                    {getLaunchAngleText(
                      subscribedUserLastCapture.vertical_launch_angle,
                      true
                    )}
                  </li>
                  <li
                    style={{
                      display: "grid",
                      gridTemplateColumns: "1fr auto 1fr",
                      justifyContent: "center",
                    }}
                  >
                    <strong style={{ textAlign: "right" }}>
                      Horizontal Launch Angle:
                    </strong>
                    {getLaunchAngleText(
                      subscribedUserLastCapture.horizontal_launch_angle
                    )}
                  </li>
                  <li
                    style={{
                      display: "grid",
                      gridTemplateColumns: "1fr auto 1fr",
                      justifyContent: "center",
                    }}
                  >
                    <strong style={{ textAlign: "right" }}>Spin:</strong>
                    {subscribedUserLastCapture.spin?.toFixed(0)} (rpm)
                  </li>
                  <li
                    style={{
                      display: "grid",
                      gridTemplateColumns: "1fr auto 1fr",
                      justifyContent: "center",
                    }}
                  >
                    <strong style={{ textAlign: "right" }}>Spin Axis:</strong>
                    {getLaunchAngleText(subscribedUserLastCapture.spin_axis)}
                  </li>
                </ul>
              </Card>
              <CaptureViewer lastCapture={subscribedUserLastCapture} />
            </>
          ) : (
            <div>Waiting for the next shot...</div>
          )}
        </Space>
      </Col>
    </Row>
  );

  const columns = [
    { title: "Capture ID", dataIndex: "captureId", key: "captureId" },
  ];

  const renderDesktopLayout = () => (
    <Row gutter={16} justify="center">
      <Col span={24}>
        <Space direction="vertical" size="large" style={{ width: "100%" }}>
          <Row justify="space-between" align="middle">
            <Space>
              <AutoComplete
                value={searchValue}
                style={{ width: 300 }}
                options={users
                  .filter((user) => user.email)
                  .map((user) => ({ value: user.email }))}
                placeholder="Search for a user"
                filterOption={(inputValue, option) =>
                  option!.value.toUpperCase().includes(inputValue.toUpperCase())
                }
                onSelect={handleSelect}
                onChange={handleChange}
              />
              <Button
                type={subscribedUser ? "default" : "primary"}
                danger={!!subscribedUser}
                onClick={
                  subscribedUser
                    ? handleUnsubscribe
                    : () => handleSubscribe(selectedUser!)
                }
              >
                {subscribedUser ? "Unsubscribe" : "Subscribe"}
              </Button>
            </Space>
            <Button type="primary" onClick={() => dispatch(fetchUsers())}>
              Refresh Users
            </Button>
          </Row>
          <Row gutter={16}>
            {subscribedUser && (
              <>
                <Col span={12}>
                  <Card
                    title={subscribedUser?.email}
                    style={{
                      marginTop: "20px",
                      width: "100%",
                      padding: "0 20px",
                    }}
                  >
                    <Descriptions bordered layout="vertical">
                      <Descriptions.Item label="Number of Captures">
                        {subscribedUser.totalCapturesCount}
                      </Descriptions.Item>
                      <Descriptions.Item label="Number of Reported Captures">
                        {subscribedUser.reportedCapturesCount}
                      </Descriptions.Item>
                      <Descriptions.Item label="Last Capture Date">
                        {subscribedUser.lastCapture?.recorded?.toLocaleString() ||
                          "N/A"}
                      </Descriptions.Item>
                    </Descriptions>
                  </Card>
                </Col>
                <Col span={12}>
                  <Card
                    style={{
                      marginTop: "20px",
                      width: "100%",
                      padding: "0 20px",
                    }}
                  >
                    <Tabs defaultActiveKey="1">
                      <TabPane tab="Current User's Shot Data" key="1">
                        <Descriptions
                          bordered
                          column={1}
                          style={{ width: "100%" }}
                        >
                          {/* <Descriptions.Item label="Backspin">
                            {subscribedUserLastCapture?.backspin?.toFixed(1) ??
                              "N/A"}
                          </Descriptions.Item> */}
                          {/* <Descriptions.Item label="Sidespin">
                            {subscribedUserLastCapture?.sidespin?.toFixed(1) ??
                              "N/A"}
                          </Descriptions.Item> */}
                          <Descriptions.Item
                            label={
                              <span onClick={handleSpeedUnitChange}>
                                Ball Speed:
                              </span>
                            }
                          >
                            {subscribedUserLastCapture?.ball_speed
                              ? speedUnit === "mph"
                                ? (
                                    subscribedUserLastCapture.ball_speed * 2.237
                                  ).toFixed(1)
                                : speedUnit === "km/h"
                                ? (
                                    subscribedUserLastCapture.ball_speed * 3.6
                                  ).toFixed(1)
                                : subscribedUserLastCapture.ball_speed.toFixed(
                                    1
                                  )
                              : "N/A"}{" "}
                            ({speedUnit.toLowerCase()})
                          </Descriptions.Item>
                          <Descriptions.Item label="Vertical Launch Angle">
                            {getLaunchAngleText(
                              subscribedUserLastCapture?.vertical_launch_angle,
                              true
                            )}
                          </Descriptions.Item>
                          <Descriptions.Item label="Horizontal Launch Angle">
                            {getLaunchAngleText(
                              subscribedUserLastCapture?.horizontal_launch_angle
                            )}
                          </Descriptions.Item>
                          <Descriptions.Item label="Total Spin">
                            {subscribedUserLastCapture?.spin?.toFixed(0) ??
                              "N/A"}{" "}
                            (rpm)
                          </Descriptions.Item>
                          <Descriptions.Item label="Spin Axis">
                            {getLaunchAngleText(
                              subscribedUserLastCapture?.spin_axis
                            )}
                          </Descriptions.Item>
                        </Descriptions>
                      </TabPane>
                      <TabPane tab="Reported Captures" key="2">
                        <Table
                          columns={columns}
                          dataSource={subscribedUser.reportedCaptures}
                        />
                      </TabPane>
                    </Tabs>
                  </Card>
                </Col>
              </>
            )}
          </Row>
          <Row>
            {subscribedUserLastCapture && (
              <CaptureViewer lastCapture={subscribedUserLastCapture} />
            )}
          </Row>
        </Space>
      </Col>
    </Row>
  );

  return screens.xs ? renderMobileLayout() : renderDesktopLayout();
};

export default Admin;
