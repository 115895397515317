// src/routes/AppRoutes.tsx

import React, { useEffect, useState } from "react";
import {
  Routes,
  Route,
  Navigate,
  useLocation,
  useNavigate,
} from "react-router-dom";
import PrivateRoute from "./PrivateRoute";
import LoginPage from "../components/Login/LoginPage";
import Dashboard from "../components/Dashboard/Dashboard";
import Admin from "../components/Admin/Admin";
import { useAppSelector, useAppDispatch } from "../hooks/storeHooks";
import { authenticateWithToken, setLoading } from "../features/user/userSlice";

const AppRoutes = () => {
  const currentUser = useAppSelector((state) => state.user.currentUser);
  const isAuthenticating = useAppSelector((state) => state.user.isLoading);
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  // State to track token check
  const [hasCheckedToken, setHasCheckedToken] = useState(false);

  // Effect to handle token-based authentication
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const tokenFromUrl = queryParams.get("token");

    if (tokenFromUrl) {
      console.log("AppRoutes - Token found:", tokenFromUrl);
      dispatch(authenticateWithToken(tokenFromUrl)).finally(() => {
        setHasCheckedToken(true);
      });
    } else {
      console.log("AppRoutes - No token found.");
      dispatch(setLoading(false));
      setHasCheckedToken(true);
    }
  }, [dispatch, location.search]);

  // Debugging: Log state changes
  useEffect(() => {
    console.log("AppRoutes - currentUser:", currentUser);
    console.log("AppRoutes - isAuthenticating:", isAuthenticating);
    console.log("AppRoutes - hasCheckedToken:", hasCheckedToken);
  }, [currentUser, isAuthenticating, hasCheckedToken]);

  // Effect to redirect to dashboard if already on /login and authenticated
  useEffect(() => {
    if (currentUser && location.pathname === "/login") {
      console.log("AppRoutes - Redirecting to /dashboard");
      navigate("/dashboard", { replace: true });
    }
  }, [currentUser, location.pathname, navigate]);

  // Show loading state while authenticating
  if (isAuthenticating || !hasCheckedToken) {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
        }}
      >
        <h2>Authenticating...</h2>
      </div>
    );
  }

  return (
    <Routes>
      {/* Public Routes: Login */}
      <Route
        path="/login"
        element={
          currentUser ? <Navigate to="/dashboard" replace /> : <LoginPage />
        }
      />

      {/* Private Routes */}
      <Route element={<PrivateRoute />}>
        <Route path="/" element={<Navigate to="/dashboard" replace />} />
        <Route path="dashboard" element={<Dashboard />} />
        <Route path="admin" element={<Admin />} />
      </Route>

      {/* Fallback Route */}
      <Route
        path="*"
        element={
          <Navigate to={currentUser ? "/dashboard" : "/login"} replace />
        }
      />
    </Routes>
  );
};

export default AppRoutes;
